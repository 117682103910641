.filter-sort {
  flex: 1;
  display: flex;
  align-items: center;
  .title {
    font-size: 12px;
    color: #ffffff;
    padding-right: 10px;
  }
  .box {
    border-radius: 30px;
    width: 160px;
    height: 40px;
    background-color: #222222;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  b {
    flex: 1;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    color: #ffffff;
    z-index: 2;
    transition: 0.4s ease;
    &.active {
      color: #222222;
    }
  }
  .pattern {
    position: absolute;
    left: 4px;
    width: 72px;
    height: 30px;
    border-radius: 30px;
    background-color: #ffffff;
    transition: 0.4s ease;
    z-index: 1;
    &.active {
      left: 82px;
    }
  }
  @media (max-width: 767px) {
    justify-content: center;
    margin-bottom: 20px;
  }
}
