.podcast-list-item {
  width: calc((100% / 4) - 34px);
  background-color: #ffffff;
  border-radius: 6px;
  padding: 15px;
  margin-right: 34px;
  margin-bottom: 45px;
  border: 2px solid #ae1329;
  position: relative;
  padding-bottom: 70px;
  cursor: pointer;
  transition: 0.4s ease;
  &:hover {
    opacity: 0.5;
  }
  time {
    font-weight: 600;
    font-size: 12px;
    color: #1d1f26;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #ae1329;
    padding: 15px 0;
  }
  .description {
    font-size: 14px;
    line-height: 1.8;
    color: #000000;
  }
  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    background-color: #ae1329;
    display: flex;
    align-items: center;
    padding: 0 15px;
    p {
      font-weight: 600;
      font-size: 10px;
      color: #ffffff;
    }
    .play {
      position: absolute;
      right: 15px;
      top: -20px;
      width: 40px;
      height: 40px;
      span {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  @media (max-width: 1200px) {
    .title {
      font-size: 16px;
    }
  }
  @media (max-width: 1024px) {
    time {
      font-size: 10px;
    }
    .title {
      font-size: 14px;
      padding: 10px 0;
    }
    .description {
      font-size: 12px;
    }
  }
  @media (max-width: 991px) {
    width: calc((100% / 3) - 34px);
  }
  @media (max-width: 767px) {
    width: calc((100% / 2) - 34px);
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
