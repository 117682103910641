section {
  min-height: 500px;
  background: transparent url('../../assets/img/entry.jpg') center;
  .content {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 25px 50px;
    text-align: center;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      width: 100px;
      height: 100px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .language {
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      padding: 5px 10px;
      cursor: pointer;
    }
  }
  .context {
    width: 100%;
    max-width: 991px;
    margin: auto;
  }
  h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 1.4;
    color: #ffffff;
  }
  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    color: #e8d3d3;
    padding-top: 30px;
  }
  @media (max-width: 1200px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 767px) {
    min-height: auto;
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
      padding-top: 20px;
    }
    .content {
      padding: 0 20px 20px;
    }
  }
}
