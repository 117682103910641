html,
body {
  height: auto;
  min-height: 100%;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  background-color: #f3fafb;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
}
