#player-wrapper {
  height: 80px;
  background-color: #222222;
  padding: 0 40px 0 10px;
  position: fixed;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 9;
  animation: opened 1s ease;
  display: flex;
  align-items: center;
  &.no-fixed {
    position: relative;
    animation: none;
  }
  .rhap_container {
    background-color: transparent;
    padding: 0;
  }
  .rhap_progress-filled,
  .rhap_progress-indicator,
  .rhap_volume-bar,
  .rhap_volume-indicator {
    background-color: #1de9b6;
  }
  .rhap_time {
    font-size: 12px;
    color: #ffffff;
  }
  .rhap_additional-controls {
    position: absolute;
    width: 30px;
    height: 100%;
    right: 0;
  }
  .rhap_volume-container {
    flex: 1;
  }
  .rhap_main-controls {
    margin-right: 10px;
  }
  .rhap_volume-controls {
    margin-right: 10px;
  }
  .rhap_play-pause-button {
    width: 35px;
    height: 35px;
  }
  .player {
    flex: 1;
    display: flex;
    align-items: center;
    flex-flow: wrap row;
  }
  .img {
    display: block;
    width: 100%;
    height: auto;
    &.small {
      height: 20px;
    }
  }
  .info {
    max-width: 300px;
    display: flex;
    align-items: center;
    .cover {
      width: 60px;
      height: 60px;
      border-radius: 6px;
      overflow: hidden;
      background: #000000 no-repeat center;
      background-size: cover;
    }
    .content {
      flex: 1;
      overflow: hidden;
      padding: 0 10px;
    }
    .small-title,
    .title {
      color: #ffffff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .small-title {
      font-size: 12px;
    }
    .title {
      font-size: 14px;
    }
  }

  .share {
    height: 100%;
    display: flex;
    align-items: center;
    > span {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    > img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    &-box {
      position: absolute;
      width: 300px;
      right: -5px;
      bottom: 0;
      padding: 0 20px;
      background-color: #222222;
      border-top-left-radius: 10px;
      transition: 0.4s ease;
      opacity: 0;
      z-index: -9;
      &.active {
        bottom: 100%;
        opacity: 1;
        z-index: 1;
      }
    }
    &-box-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      padding: 5px 0;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
      }
    }
    span {
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .created-by {
    position: absolute;
    right: 8px;
    bottom: 5px;
    width: 120px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 991px) {
    .info {
      max-width: 200px;
    }
  }

  @media (max-width: 767px) {
    display: block;
    padding: 0 10px;
    .info {
      margin: 4px 0;
      max-width: 100%;
      height: 30px;
      .cover {
        display: none;
      }
      .content {
        padding: 0;
      }
      .small-title {
        font-size: 10px;
        padding-right: 110px;
        padding-bottom: 2px;
        opacity: 0.6;
      }
      .title {
        font-size: 12px;
      }
    }
    .share-box {
      padding: 0 10px;
      right: auto;
      left: -51px;
      border-radius: 0 10px 0 0;
      width: 200px;
      &.active {
        bottom: calc(100% + 38px);
      }
    }
    .created-by {
      bottom: auto;
      top: 4px;
      right: 10px;
      width: 100px;
    }
    .rhap_additional-controls {
      position: relative;
      order: 1;
      justify-content: center;
    }
    .rhap_volume-controls,
    .rhap_rewind-button,
    .rhap_forward-button {
      display: none;
    }
    .rhap_controls-section {
      max-width: 70px;
    }
    .rhap_main-controls {
      margin-right: 0;
    }
    .rhap_progress-section {
      flex: 1;
    }
  }
}

@keyframes opened {
  0% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes playing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
