#filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 767px) {
    display: block;
    margin-top: 20px;
  }
}
