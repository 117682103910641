.filter-search {
  flex: 2;
  display: flex;
  align-items: center;
  background-color: #222222;
  height: 40px;
  border-radius: 30px;
  padding: 0 4px 0 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  img {
    width: 20px;
    height: 20px;
  }
  input {
    flex: 1;
    margin: 0 10px;
    background-color: transparent;
    border: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 35px;
    color: #ffffff;
  }
  .button {
    width: 60px;
    height: 32px;
    border-radius: 30px;
    background-color: #ae1329;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    color: #ffffff;
    cursor: pointer;
    transition: 0.4s ease;
    &.clear {
      margin-right: 5px;
      background-color: #5613ae;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 991px) {
    flex: 1.5;
  }
  @media (max-width: 767px) {
    img {
      width: 15px;
      height: 15px;
    }
    input,
    .button {
      font-size: 10px;
    }
    .button {
      width: 50px;
    }
  }
}
