#podcast-list {
  width: 100%;
  max-width: 1440px;
  padding: 0 50px;
  margin: auto;
  > .title {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    padding: 30px 0;
  }
  .list {
    overflow: hidden;
  }
  ul {
    display: flex;
    flex-flow: row wrap;
    margin-right: -34px;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    > .title {
      font-size: 20px;
      text-align: center;
      padding: 20px 0;
    }
  }
  @media (max-width: 600px) {
    ul {
      margin-right: 0;
    }
  }
}
