footer {
  border-top: 1px solid #bba4a4;
  font-weight: 300;
  font-size: 14px;
  padding: 10px 20px;
  &.player {
    margin-bottom: 80px;
  }
  > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #22222270;
    padding-bottom: 10px;
  }
  .bottom,
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom {
    p {
      font-size: 12px;
      padding-right: 20px;
      color: #22222270;
    }
  }
  ul {
    li:not(:last-child) {
      margin-right: 20px;
    }
    a {
      font-size: 12px;
      color: #22222270;
    }
  }
  @media (max-width: 767px) {
    > p {
      font-size: 12px;
    }
  }
}
